@import "fonts/inter.css";

html {
    font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: 'Inter var', sans-serif;
    }
}

body {
    margin: 0;
    color: white;
}

html {
    font-size: 16px;
}

a {
    color: #12b0e0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
